export default [{
    _name: 'CSidebarNav',
    _children: [{
            _name: 'CSidebarNavItem',
            name: 'sidebar.dashboard',
            to: '/dashboard',
            icon: 'cil-speedometer',
            items: []
        },
        {
            _name: 'CSidebarNavDropdown',
            name: 'Withdraw',
            route: '/withdraw',
            icon: 'cil-dollar',
            items: [{
                    name: 'Pending List',
                    to: '/withdraw/pending'
                },
                {
                    name: 'Approve List',
                    to: '/withdraw/approve'
                },
                {
                    name: 'Reject List',
                    to: '/withdraw/reject'
                },
                {
                    name: 'All List',
                    to: '/withdraw/all'
                }
            ]
        },
        {
            _name: 'CSidebarNavDropdown',
            name: 'Users',
            route: '/users',
            icon: 'cil-user',
            items: [{
                    name: 'All Users',
                    to: '/users/all'
                },
                {
                    name: 'Active Users',
                    to: '/users/active'
                },
                {
                    name: 'Block Users',
                    to: '/users/block'
                },
                {
                    name: 'Active Now',
                    to: '/users/activeusers'
                }
            ]
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Settings',
            to: '/other/setting',
            icon: 'cil-settings',
            items: []
        },
        {
            _name: 'CSidebarNavItem',
            name: 'App Update',
            to: '/other/update',
            icon: 'cil-ban',
            items: []
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Link Update',
            to: '/other/link-update',
            icon: 'cil-ban',
            items: []
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Article Link',
            to: '/other/article-link',
            icon: 'cil-ban',
            items: []
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Follow Link',
            to: '/other/follow-link',
            icon: 'cil-ban',
            items: []
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Game Link',
            to: '/other/game-link',
            icon: 'cil-ban',
            items: []
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Video Link',
            to: '/other/video-link',
            icon: 'cil-ban',
            items: []
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Youtube Link',
            to: '/other/youtube-link',
            icon: 'cil-ban',
            items: []
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Movie Video Link',
            to: '/other/movie-video-link',
            icon: 'cil-ban',
            items: []
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Extra Video Link',
            to: '/other/extra-video-link',
            icon: 'cil-ban',
            items: []
        },
        {
            _name: 'CSidebarNavItem',
            name: 'IP List (Unused)',
            to: '/other/unused-ip-list',
            icon: 'cil-ban',
            items: []
        },
        {
            _name: 'CSidebarNavItem',
            name: 'IP List (Used)',
            to: '/other/used-ip-list',
            icon: 'cil-ban',
            items: []
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Payment Method',
            to: '/other/payment-method',
            icon: 'cil-ban',
            items: []
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Notification',
            to: '/other/notifications',
            icon: 'cil-ban',
            items: []
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Send Notification',
            to: '/other/send-notification',
            icon: 'cil-ban',
            items: []
        }
    ]
}]